<template>
  <v-container grid-list-xs v-if="!loading && show">
    <v-row>
      <v-col cols="12">
        <h4>Last Call Preferences:</h4>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="mb-4">
        <v-switch
          color="primary"
          v-model="subscribedToLastCall"
          class="mx-2"
          label="Subscribe to Last Call"
          @change="subscribeToLastCall()"
        ></v-switch>
      </v-col>
    </v-row>

    <template v-if="subscribedToLastCall" class="mb-4">
      <v-row>
        <v-col cols="12">
          <v-switch
            color="primary"
            v-model="lastCallPaused"
            class="mx-2"
            label="Pause Notifications"
            @change="pauseLastCall()"
          ></v-switch>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col cols="12">
          <h4>Add filters to last call:</h4>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <food-categories
            :init="typeFilters"
            @updateTypeFilters="updateTypeFilters($event, true)"
          />
          <LastCallRestaurants
            :init="restFilters"
            @updateRestFilters="updateRestFilters($event, true)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn color="primary" @click.prevent="save">Save Filters</v-btn>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import FoodCategories from '@/components/common/FoodCategories'
import LastCallRestaurants from '@/components/common/LastCallRestaurants'

export default {
  components: {
    FoodCategories,
    LastCallRestaurants
  },
  data() {
    return {
      subscriptions: [],
      show: false,
      subscribedToLastCall: false,
      lastCallPaused: false,
      typeFilters: [],
      restFilters: [],
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      profile: 'profile',
      searchLocation: 'searchLocation',
      userLocation: 'userLocation'
    })
  },
  mounted() {
    this.$store.dispatch('getSubscription').then(list => {
      this.subscriptions = list
      const lastCalls = this.subscriptions.filter(s => s.type == 'last_call')
      if (lastCalls.length > 0) {
        this.subscribedToLastCall = true
        this.lastCallPaused = lastCalls[0].isPaused
        this.typeFilters = lastCalls[0].type_filters
        this.restFilters = lastCalls[0].restaurant_filters
      }
      this.$store.dispatch('getLastCallRestaurants').then(() => {
        this.show = true
      })
    })
  },
  methods: {
    subscribeToLastCall() {
      if (this.subscribedToLastCall) {
        this.$store.dispatch('subscribeToLastCall', { radius: 10 })
        this.$store.dispatch('getSubscription').then(list => {
          this.subscriptions = list
        })
      } else {
        this.$store.dispatch('unsubscribeToChannel', { type: 'last_call' })
        this.pauseLastCall = false
        this.subscriptions = []
      }
    },
    pauseLastCall() {
      this.$store.dispatch('updateLastCall', { isPaused: this.lastCallPaused })
    },
    updateTypeFilters(payload, liked) {
      console.log('payload', payload, liked)
      if (liked) {
        this.typeFilters = payload
      }
    },
    updateRestFilters(payload, liked) {
      console.log('payload', payload, liked)
      if (liked) {
        this.restFilters = payload
      }
    },
    save() {
      this.typeFilters = this.typeFilters ? this.typeFilters : null
      this.restFilters = this.restFilters ? this.restFilters : null
      this.$store.dispatch('updateLastCall', {
        type_filters: this.typeFilters,
        restaurant_filters: this.restFilters
      })
    }
  }
}
</script>
